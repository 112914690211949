import clsx from "clsx";
import React from "react";
import "../../asset/css/main.css";
// lib
import { useNavigate } from "react-router-dom";

const PopupBox = ({
  handleToggle,
  id,
  children,
  title = "Popup Box",
  contentStyle = { maxWidth: "480px" },
  showBack = false,
  isTitleCenter = false,
  isFull = false,
}) => {
  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="popup" id={id}>
      <div className={clsx("overlay")}></div>
      <div
        className={clsx(
          "w-100 bg-white",
          isFull ? "h-100 fullContent rounded-0" : "content rounded-2"
        )}
        style={contentStyle}
      >
        <div
          id={id}
          onClick={showBack ? goBack : handleToggle}
          className="close-btn cursor-pointer bg-white text-black"
        >
          <span
            className=" position-absolute translate-middle-y"
            style={{ top: "25%" }}
          >
            &times;
          </span>
        </div>

        <h6 className={`${isTitleCenter && "text-center"} fw-bold text-black`}>
          {title}
        </h6>
        {children}
      </div>
    </div>
  );
};

export default PopupBox;
