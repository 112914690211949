import { getConfig } from "./apiHeader";
import {
  GameLunch,
  GetGameCategoryURL,
  GetGameProviderURL,
  GetGameURL,
} from "./apiUrl";

export const GetGameCategories = async () => {
  const response = await fetch(GetGameCategoryURL, getConfig);
  if (response.ok) return await response.json();
  else throw response;
};

export const GetGameProviders = async (id) => {
  const response = await fetch(`${GetGameProviderURL}/${id}`, getConfig);
  if (response.ok) return await response.json();
  else throw response;
};

export const GetGames = async (cat, prov) => {
  const response = await fetch(
    `${GetGameURL}?category=${cat}&provider=${prov}`,
    getConfig
  );
  if (response.ok) return await response.json();
  else throw response;
};

export const Gameplay = async (user_code, p_code, cate_code, g_code) => {
  const response = await fetch(
    `${GameLunch}/${user_code}/${p_code}/${cate_code}/${g_code}/0`,
    getConfig
  );
  if (response.ok) return await response.json();
  else throw response;
};
