export const CHINESE = {
    home : '主要的',
    mainBalance: '总余额',
    gameBalance: '遊戲平衡',
    profile: '简介',
    friend: '朋友',
    betslip: '投注單',
    history: '投注历史',
    cashIn: '现金收入',
    cashOut: '现金支出',
    logout: '退出',
    login: '登入',
    gameCode: "遊戲代碼",
    turnOver: "周轉",
    transferPlaceHolder: "請輸入您要轉賬的金額",
    pleaseTransfer: "請轉移",
    searchGame: "请在此搜索...",
    twoD: "2D",
    threeD: "3D",
    winNoHistory: "幸運數字",
    moreGame: "更多游戏",
    playNow: "马上开始",
    transfer: "轉移",
    apply: "申請",
    notice: "注意",
    back: "后退",
    category: {
        newGame: "新遊戲",
        football: "足球",
        lottery: "彩票",
        cardGame: "紙牌遊戲",
        liveCasino: "真人赌场",
        fishingGame: "釣魚游戲",
        slotGame: "老虎機遊戲",
        promotion: "晉升",
        blog: "博客",
        other: "其他"
    },
    profilePage: {
        level1: "您的帳戶是 1 級",
        level2: "您的帳戶為 2 級",
        upgradeLevel: "升級等級",
        upgradeToLevel2: "將您的帳戶升級到第 2 級",
        level2Image: "請上傳 Wave Pay、KBZPay 或 AYA Pay Level 2 的截圖",
        changePassword: "更改密码",
        oldPassword: "舊密碼",
        newPassword: "新密码",
        userName: "用户名",
        email: "電子郵件",
        phone: "电话",
        dateOfBirth: "出生日期",
        referralCode: "您的推薦代碼",
        yourReferralCode: '您的推荐码',
        editProfile: "编辑简介",
        confirm: "确认密码",
        send: "发送"
    },
    referralPage: {
        yourFriend: "你的朋友們",
        gameRefAmount: "遊戲推薦金額",
        claim: '宣稱',
        name: '名字',
        date: '日期',
        section: '部分',
        amount: '數量'
    },
    betslipPage: {
        twodSlips: '2D 單據',
        threedSlips: '3D 單據',
        cashinHistory: '現金歷史',
        cashoutHistory: '兌現歷史',
        maintoGameBalanceHistory: '主要到遊戲平衡歷史',
        gametoMainBalanceHistory: '遊戲到主平衡歷史',
        gameHistory: '遊戲歷史',
        date: '日期',
        threeddate: '3D 日期',
        section: '部分',
        totalAmount: '總金額',
        totalBetNumbers: '總投注數',
        winAmount: '贏額',
        status: '地位',
        paymentName: '付款名稱',
        accountHolderName: '賬戶持有人姓名',
        transationID: '交易代码',
        oldBalance: '舊平衡',
        cashinAmount: '現金金額',
        cashoutAmount: '提款金額',
        newBalance: '新百倫',
        winloss: '贏/輸',
        commission: '委員會',
        profitloss: '利潤損失',
        mainBalance: '总余额',
        transferAmount: '轉賬金額',
        gameBalance: '遊戲平衡',
        referenceID: '參考編號',
        validTurnover: '有效營業額',
        userId: "用戶身份",
        gameType: "遊戲類型",
        stake: "賭注"
    },
    cashInPage: {
        cashin: '现金收入',
        holdername: '持有人名稱',
        transationID: '交易代码',
        lastsixdigits: '最後 6 位數字',
        accountNumber: '帳號',
        amount: '數量',
        transferToAmount: '請轉入以下賬戶',
        request: '要求',
        promoCode: '促銷代碼',
        voucherCode: "Voucher Code"
    },
    cashOutPage: {
        cashout: '现金支出',
        holdername: '持有人名稱',
        accountNumber: '帳號',
        amount: '數量',
        request: '要求'
    },
    loginPage: {
        signIn: "登入",
        phone: "电话",
        password: "密码",
        registerAcc: "註冊一個帳戶",
        forgetPassword: "忘記密碼？"
    },
    registerPage: {
        userName: "用户名",
        phone: "电话",
        password: "密码",
        refferalCode: "推薦碼",
        register: "登記",
        accountAlreadyExist: "已經有賬號？",
        changePhone: "換手機",
        send: "发送"
    },
    forgetPassPage: {
        phone: "电话",
        send: "发送",
        password: "密码",
        confirmPassword: "确认密码",
        resetPassTitle: "输入您注册的手机号码。"
    },
    sectionTwoD: {
        lottery: "彩票",
        sportBook: "體育書",
        liveCasino: "真人赌场"
    },
    warning: {
        setAmount: "請先設置金額。",
        bettingClosed: "投注已關閉。",
        lotteryClosed: "沒有數字數據，彩票關閉了嗎？",
        needLevel2: "您的帳戶需要為 2 級。",
        transferMainToGame: "請至少將 Main 轉移到 Game 一次。",
        cannotClaim: "不能要求少於 0 的遊戲推薦金額。"
    },
    success: {
        cashInReq: "已發送收銀請求！",
        cashOutReq: "已發送提款請求！",
        successChangePass: "你已經成功更改密碼！",
        verifyCodeSent: "驗證碼已發送！"
    },
    winHistoryPage: {
        luckyNo: "幸運數字",
        section: "部分",
        date: "日期"
    },
    winnerListHistory : {
        title : "获奖名单",
        section : "部分",
        lucky_number : "幸運數字",
        no : "不。",
        phone : "电话号码",
        bet_amount : "投注金额",
        win_amount : "中奖金额"
    }
}