import { BaseURL } from "../api/apiUrl";
import NoImage from "../asset/image/no-image.png";

export const formatPhone = (phone) => {
  if (phone?.length !== 11) {
    return undefined;
  }

  const fPhone = phone.replace(/\d(?=(?:\D*\d){3})/g, "*").substring(2, 11);

  return "09" + fPhone;
};

export const formatMoney = (amount) => {
  const money = Intl.NumberFormat("en-us").format(Math.floor(amount));
  return money;
};

export const formatImage = (url) => {
  if (!url) return NoImage;
  return url.startsWith("blob") ? url : `${BaseURL}/${url}`;
};
