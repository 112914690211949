// @ts-nocheck
import React from "react";
import LanguageModal from "./modals/Language";
import PointModal from "./modals/Point";
import Lottery from "./modals/Lottery";
import Usercode from "./modals/UserCode";

const Modals = () => {
  return (
    <>
      <LanguageModal />
      <PointModal />
      <Lottery />
      <Usercode />
    </>
  );
};

export default Modals;
