// @ts-nocheck
import React from "react";
import CommonModal from "../shared_comp/Modal";
import { usePointModal } from "../../hooks/useLanguage";

const Language = () => {
  const { isOpen, onOpen, onClose, pData } = usePointModal();

  return (
    <CommonModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onRequestClose={true}
      label={pData.point_header}
      onClick={() => {}}
      isFull={false}
    >
      <div className="d-flex flex-column gap-2">
        <small
          style={{
            letterSpacing: ".02rem",
            lineHeight: "1.7rem",
            textAlign: "justify",
            fontSize: "13px",
          }}
          className="text-black text-opacity-75 pb-4"
        >
          {pData.point_body}
        </small>
      </div>
    </CommonModal>
  );
};

export default Language;
