import { create } from "zustand";

export const useLanguageModal = create((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}));

export const usePointModal = create((set) => ({
  isOpen: false,
  pData: {},
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  setPData: (data) => set({ pData: data }),
}));

export const useLottery = create((set) => ({
  isLotteryOpen: false,
  onLotteryOpen: () => set({ isLotteryOpen: true }),
  onLotteryClose: () => set({ isLotteryOpen: false }),
}));

export const usePrivacy = create((set) => ({
  isPrivacyOpen: false,
  onPrivacyOpen: () => set({ isPrivacy: true }),
  onPrivacyClose: () => set({ isPrivacy: false }),
}));

export const useRegister = create((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}));
