// @ts-nocheck
import React, { useState } from "react";
import CommonModal from "../shared_comp/Modal";
import { useRegister } from "../../hooks/useLanguage";
import { useContext } from "react";
import { LoginContext } from "../../store/context/LoginProvider";
import clsx from "clsx";

const Usercode = () => {
  const [{ userData }] = useContext(LoginContext);
  const { isOpen, onOpen, onClose } = useRegister();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(`phone: ${userData?.phone}, password: ${userData?.pp_copy} `)
      .then(
        () => {
          setCopied(true);
        },
        (err) => {
          console.error("Could not copy text: ", err);
        }
      );
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onRequestClose={true}
      label={"Welcome " + userData?.name}
      onClick={() => {}}
      isFull={false}
    >
      <div className="d-flex flex-column gap-2">
        <small
          style={{
            letterSpacing: ".02rem",
            lineHeight: "1.7rem",
            textAlign: "justify",
            fontSize: "13px",
          }}
          className="text-black text-opacity-75 pb-4"
        >
          This is your crendentials. Copy and save it.
          <br />
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <b className="fs-6 d-block text-primary">{userData?.phone}</b>
              <b className="fs-6 text-primary">{userData?.pp_copy}</b>
            </div>
            <button
              className={clsx(
                "btn btn-sm ml-auto",
                copied && "btn-success",
                !copied && "btn-outline-dark"
              )}
              style={{ fontSize: "12px" }}
              onClick={copyToClipboard}
            >
              {!copied ? <i className="fa fa-clipboard" /> : "✅ Copied"}
            </button>
          </div>
        </small>
      </div>
    </CommonModal>
  );
};

export default Usercode;
