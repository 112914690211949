import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

const FooterLink = ({ handleUrl, icon: Icon, label, isActive }) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={handleUrl}
      className={clsx(
        "d-flex flex-column align-items-center cursor-pointer",
        isActive ? "text-warning" : "text-white"
      )}
    >
      <Icon size={28} />
      <span className="pt-2 fw-bolder" style={{ fontSize: "0.7rem" }}>
        {t(label)}
      </span>
    </div>
  );
};

export default FooterLink;
