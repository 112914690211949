// @ts-nocheck
import clsx from "clsx";
import React, { useCallback } from "react";
import Modal from "react-modal";

import { AiOutlineClose } from "react-icons/ai";

const customStyles = {
  overlay: {
    backgroundColor: "#000000e1",
    zIndex: 9999,
  },
};

Modal.setAppElement("#root");

const CommonModal = ({
  isOpen,
  onOpen,
  onClose,
  isFull,
  label,
  onClick,
  onRequestClose,
  children,
}) => {
  const toggleModal = useCallback(() => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  }, [isOpen, onOpen, onClose]);
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={onRequestClose && toggleModal}
      className={clsx("commonModal-min", isFull && "commonModal")}
    >
      <div className="text-black d-flex flex-column">
        <nav className="d-flex justify-content-between border-bottom p-3">
          <div style={{ letterSpacing: "-0.5px", wordSpacing: ".1rem" }}>
            {label}
          </div>
          <AiOutlineClose role="button" onClick={toggleModal} size={20} />
        </nav>
        <main className="p-3">{children}</main>
      </div>
    </Modal>
  );
};

export default CommonModal;
