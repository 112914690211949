export const MYANMAR = {
  home: "ပင်မ",
  wallet: "ဆိုင်",
  gift: "ဆု",
  service: "အကူအညီ",
  setting: "ဆက်တင်",
  mainBalance: "လက်ကျန်ငွေ",
  turnover: "Turnover amount",
  profile: "အကောင့်",
  friend: "သူငယ်ချင်း",
  betslip: "ပြေစာ",
  history: "မှတ်တမ်း",
  cashIn: "ငွေသွင်း",
  cashOut: "ငွေထုတ်",
  logout: "အကောင့်ထွက်မည်",
  login: "အကောင့်ဝင်ရန်",
  gameCode: "ဂိမ်းကုဒ်",
  turnOver: "Turnover",
  transferPlaceHolder: "ကျေးဇူးပြု၍ လွှဲပြောင်းလိုသော ပမာဏကို ထည့်သွင်းပါရန်",
  pleaseTransfer: "ငွေလွှဲပြောင်းခြင်းကိုလုပ်ဆောင်ရန်",
  searchGame: "ဂိမ်းများရှာဖွေရန်...",
  twoD: "2D",
  threeD: "3D",
  oneD: "1D",
  winNoHistory: "ထီပေါက်စဥ်",
  moreGame: "ဂိမ်းအားလုံးကြည့်ရန်",
  playNow: "ကစားရန်",
  transfer: "ပြောင်းရန်",
  apply: "စာရင်းသွင်းရန်",
  notice: "သတိပေးချက်",
  back: "နောက်သို့",
  noresult: "သက်ဆိုင်သောGameအား ရှာမတွေ့ပါ",
  category: {
    newGame: "ဂိမ်းအသစ်များ",
    football: "အားကစား",
    lottery: "ထီချဲ",
    cardGame: "ဖဲဂိမ်းများ",
    liveCasino: "ကာစီနိုဂိမ်းများ",
    fishingGame: "ငါးဖမ်းဂိမ်းများ",
    slotGame: "စလော့ဂိမ်းများ",
    promotion: "ပရိုမိုးရှင်း",
    blog: "ဘလော့်",
    other: "အခြားဂိမ်းများ",
  },
  profilePage: {
    level1: "အဆင့် ၁ အကောင့်",
    level2: "အဆင့် ၂ အကောင့်",
    upgradeLevel: "အဆင့်မြှင့်တင်ရန်",
    upgradeToLevel2: "အဆင့် ၂ မြှင့်တင်ရန်",
    level2Image: "Wave Pay, KBZPay or AYA Pay level 2 screenshot ထည့်ရန်",
    changePassword: "လျှို့ဝှက်နံပါတ်ပြောင်းရန်",
    oldPassword: "ယခင်လျှို့ဝှက်နံပါတ်",
    newPassword: "လျှို့ဝှက်နံပါတ်အသစ်",
    userName: "အမည်",
    email: "အီးမေးလ်",
    phone: "ဖုန်းနံပါတ်",
    dateOfBirth: "မွေးသက္ကရာဇ်",
    referralCode: "မိတ်ဆက်ကုဒ်",
    yourReferralCode: "သင်၏မိတ်ဆက်ကုဒ်",
    editProfile: "ကိုယ်ရေးအကျဉ်းအားပြင်ဆင်ရန်",
    confirm: "အတည်ပြုရန်",
    send: "ပေးပို့ရန်",
  },
  referralPage: {
    yourFriend: "သင့်သူငယ်ချင်းများ",
    gameRefAmount: "Game Referral အတွက်ရရှိသောပမာဏ",
    claim: "ရယူရန်",
    name: "အမည်",
    date: "ရက်စွဲ",
    section: "အချိန်",
    amount: "ပမာဏ",
  },
  betslipPage: {
    twodSlips: "၂လုံးထီမှတ်တမ်း",
    threedSlips: "၃လုံးထီမှတ်တမ်း",
    cashinHistory: "Coin ဝယ်ယူမှု မှတ်တမ်း",
    cashoutHistory: "ငွေထုတ်မှတ်တမ်း",
    maintoGameBalanceHistory:
      "ပင်မပိုက်ဆံမှဂိမ်းပိုက်ဆံသို့ပြောင်းခြင်းမှတ်တမ်း",
    gametoMainBalanceHistory:
      "ဂိမ်းပိုက်ဆံမှပင်မပိုက်ဆံသို့ပြောင်းခြင်းမှတ်တမ်း",
    gameHistory: "ဂိမ်းမှတ်တမ်း",
    date: "ရက်စွဲ",
    threeddate: "၃လုံးထီရက်စွဲ",
    section: "အချိန်",
    totalAmount: "စုစုပေါင်းပမာဏ",
    totalBetNumbers: "စုစုပေါင်းထိုးဂဏန်း",
    winAmount: "အနိုင်ရရှိသောငွေ",
    status: "အခြေအနေ",
    paymentName: "ငွေပေးချေမှုအမည်",
    accountHolderName: "အကောင့်အမည်",
    transationID: "လုပ်ငန်းစဥ်အမှတ်",
    oldBalance: "ယခင်လက်ကျန်ငွေ",
    cashinAmount: "ငွေသွင်းပမာဏ",
    cashoutAmount: "ငွေထုတ်ပမာဏ",
    newBalance: "ယခုလက်ကျန်ငွေ",
    winloss: "အနိုင်/အရှုံး",
    commission: "ကော်မရှင်",
    profitloss: "အမြတ်/အရှုံး",
    mainBalance: "ပင်မပိုက်ဆံ",
    transferAmount: "လွှဲပြောင်းပမာဏ",
    gameBalance: "ဂိမ်းပိုက်ဆံ",
    referenceID: "ရည်ညွှန်းအမှတ်",
    validTurnover: "Valid Turnover",
    userId: "သုံးစွဲသူအိုင်ဒီ",
    gameType: "ဂိမ်းအမျိုးအစား",
    stake: "လောင်းကြေး",
  },
  cashInPage: {
    cashin: "Coin ဝယ်မည်",
    holdername: "ကိုင်ဆောင်သူအမည်",
    transationID: "လုပ်ငန်းစဥ်အမှတ်",
    lastsixdigits: "နောက်ဆုံးဂဏန်း ၆ လုံး",
    accountNumber: "အကောင့်နံပါတ်",
    amount: "ပမာဏ",
    transferToAmount: "ကျေးဇူးပြု၍ အောက်ပါအကောင့်သို့ ငွေလွဲပြောင်းပါ။",
    request: "ငွေသွင်းရန်",
    promoCode: "လျှော့စျေးကုဒ် ",
    voucherCode: "ခဲခြစ်နံပါတ်",
  },
  cashOutPage: {
    cashout: "ငွေထုတ်",
    holdername: "ကိုင်ဆောင်သူအမည်",
    accountNumber: "အကောင့်နံပါတ်",
    amount: "ပမာဏ",
    request: "ငွေထုတ်ရန်",
  },
  loginPage: {
    signIn: "အကောင့်ဝင်ရန်",
    phone: "ဖုန်းနံပါတ်",
    password: "လျှို့ဝှက်နံပါတ်",
    registerAcc: "အကောင့်အသစ်လုပ်ရန်",
    forgetPassword: "လျှို့ဝှက်နံပါတ် မေ့သွားပြီလား?",
  },
  registerPage: {
    userName: "အမည်",
    phone: "ဖုန်းနံပါတ်",
    password: "လျှို့ဝှက်နံပါတ်",
    refferalCode: "ရည်ညွှန်းနံပါတ်",
    register: "အကောင့်လုပ်ရန်",
    accountAlreadyExist: "အကောင့်အသစ် ရှိပြီးသားလား?",
    changePhone: "ဖုန်းနံပါတ်ချိန်းရန်",
    send: "အတည်ပြုရန်",
  },
  forgetPassPage: {
    phone: "ဖုန်းနံပါတ်",
    send: "အတည်ပြုရန်",
    password: "လျှို့ဝှက်နံပါတ်",
    confirmPassword: "လျှို့ဝှက်နံပါတ်အတည်ပြုခြင်း",
    resetPassTitle: "အကောင့်ဖွင့်ထားသော ဖုန်းနံပါတ်ကို ထည့်သွင်းပါ",
  },
  sectionTwoD: {
    lottery: "ထီချဲ",
    sportBook: "အားကစား",
    liveCasino: "တိုက်ရိုက်လွှင့်ကာစီနိုဂိမ်းများ",
  },
  warning: {
    setAmount: "လောင်းကြေးပမာဏထည့်သွင်းပါရန်",
    bettingClosed: "လောင်းကြေးတင်ခြင်းကိုပိတ်ထားသည်",
    lotteryClosed: "ထီချဲပိတ်ရက်ဖြစ်သည်",
    needLevel2: "သင့်အကောင့်သည် အဆင့်၂ မြင့်တင်ဖို့လိုအပ်သည်။",
    transferMainToGame:
      "ပင်မပိုက်ဆံ မှ ဂိမ်းပိုက်ဆံ သို့ အနည်းဆုံးတစ်ကြိမ်ပြောင်းထားရမည်။",
    cannotClaim: "Claim လုပ်ရန်ပမာဏမလုံလောက်ပါ။",
  },
  success: {
    cashInReq: "ငွေသွင်းခြင်း အောင်မြင်သည်။",
    cashOutReq: "ငွေထုတ်ခြင်း အောင်မြင်သည်။",
    successChangePass: "လျှို့ဝှက်နံပါတ်အသစ် ပြောင်းခြင်း အောင်မြင်သည်။",
    verifyCodeSent: "အတည်ပြုချက် OTP Code ပေးပို့လိုက်သည်။",
  },
  winHistoryPage: {
    luckyNo: "ပေါက်ဂဏန်း",
    section: "အချိန်",
    date: "ရက်စွဲ",
  },
  winnerListHistory: {
    title: "အနိုင်ရသူများ",
    section: "အချိန်",
    lucky_number: "ပေါက်ဂဏန်း",
    no: "အမှတ်စဉ်",
    phone: "ဖုန်းနံပါတ်",
    bet_amount: "ထိုးကြေးပမာဏ",
    win_amount: "အနိုင်ရရှိငွေ",
    pocket: "ဆုရရှိငွေ",
  },

  promoWinner: {
    title: "Pocket Money ရရှိသူများ",
  },

  settings: {
    language: "ဘာသာစကား",
    privacy: "အချက်အလက်မူဝါဒ",
    rating: "အဆင့်သတ်မှတ်ချက်",
    share: "မျှဝေရန်",
    version: "ဗားရှင်း",
  },

  "one/lottery": "တစ်လုံးထီ",
  "two/lottery": "နှစ်လုံးထီ",
  "three/lottery": "သုံးလုံးထီ",
  "crypto/one/lottery": "ခရစ်ပတိုတစ်လုံးထီ",
  "crypto/lottery": "ခရစ်ပတိုနှစ်လုံးထီ",
  cashin: "Coin ဝယ်မည်",
  "cashin-history": "Coin မှတ်တမ်း",

  lottery: {
    bet: "ထိုးမည်",
    luckyno: "ထွက်ဂဏန်း",
    slip: "ဘောင်ချာ",
    section: {
      close: "ယနေ့အတွက် ထီပိတ်ပြီ",
      choose: "ထိုးမည့်အချိန် ရွေးပါ",
      change: "အချိန် ပြောင်းမည်",
    },
    quick: "အမြန်",
    round: "ပတ်လည်",
    enterAmount: "🪙 ထိုးကြေးထည့်ပါ",
    number: "နံပါတ်",
    odd: "ဆ",
    amount: "ထိုးကြေး",
    edit: "ပြင်ဆင်",
    delete: "ဖျက်",
    reset: "အကုန်ဖျက်",
    confirm: "ထိုးမည်",
  },
  needtologin: "အရင်ဆုံး အကောင့်ဝင်ပေးဖို့ လိုအပ်ပါသည်။",
};
