export const postConfig = (token = null) => {
    let config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    if (token) {
        return {
            ...config,
            headers: {
                ...config?.headers,
                'Authorization': `Bearer ${token}`
            }
        }
    }
    return { ...config };
}

export const getConfig = {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
}
