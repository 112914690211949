// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom/client";
// lib
import "react-lazy-load-image-component/src/effects/blur.css";
import { BrowserRouter as Router } from "react-router-dom";
import i18n from "./localization/i18n";
import { I18nextProvider } from "react-i18next";
// comp
import Global from "./Global";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18n}>
    <Router>
      <Global />
    </Router>
  </I18nextProvider>
);
