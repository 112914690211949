// @ts-nocheck
/* eslint-disable jsx-a11y/no-distracting-elements */
import React from "react";

const HeaderMarquee = ({ marquee }) => {
  return (
    <div style={{ width: "100%" }} className="mx-auto">
      <marquee className="fw-bolder text-white-50">
        <span style={{ fontSize: "0.65rem" }}>{marquee}</span>
      </marquee>
    </div>
  );
};
export default HeaderMarquee;
