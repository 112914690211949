// @ts-nocheck
import React from "react";
import { PuffLoader } from "react-spinners";

const FullLoad = () => {
  return (
    <div
      className="w-100 h-100 bg-black bg-opacity-50 position-absolute top-0 start-0 d-flex justify-content-center align-items-center"
      style={{ zIndex: "1" }}
    >
      <PuffLoader
        size={80}
        color="white"
        className="position-absolute top-50 start-50 translate-middle"
      />
    </div>
  );
};
export default FullLoad;
